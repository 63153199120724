import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const NewGame = () => {
  //const [numInputs, setInputs] = useState([]);
  const name = useRef(null);
  const history = useNavigate();
  const createGame = (e) => {
    e.preventDefault();
    const gameName = name.current.value;
    // var inputValues = document.getElementsByClassName("player");
    // inputValues = Array.from(inputValues)
    // var players = [];
    // inputValues.forEach((el, i) => {
    //     players.push({ user_name: el.value });
    // })
    fetch(`${process.env.REACT_APP_API_URI}/api/games/new`, {
      method: "POST",
      body: JSON.stringify({ name: gameName }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            history("/");
          } else {
            console.log(json.errors[0].msg);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className=' mx-auto px-6 py-8'>
        <h3 className='text-gray-700 text-3xl font-medium'>Create new game</h3>
      </div>
      <form className='flex flex-col mt-8' onSubmit={createGame}>
        <div className='align-middle pl-6 min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
          <div className='mb-4 mt-4'>
            <label
              className='block text-grey-darker text-sm font-bold mb-2'
              htmlFor='name'>
              Game Name
            </label>
            <input
              className='shadow appearance-none border rounded py-2 px-3 text-grey-darker mb-3 hover:border-gray-300 focus:outline-none focus:bg-white focus:shadow-outline focus:border-gray-300'
              id='name'
              type='text'
              placeholder='Name'
              required
              ref={name}
            />
          </div>
          {/* <div className="mb-4 ">
                            <div className="block text-grey-darker text-sm font-bold mb-2" htmlFor="players">
                                Players
                            </div>
                            <div className="flex flex-col w-1/6">
                                {numInputs.map((input) => (
                                    <Player key={input} id={input} />
                                ))}
                                <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold w-8 h-8 text-xl rounded-full focus:outline-none" onClick={addPlayer}>
                                    +
                        </button>
                            </div>
                        </div> */}
        </div>

        <button
          type='submit'
          className='bg-purple-600 hover:bg-purple-700 w-32  text-white font-bold ml-4 mt-4 px-3 py-2 rounded-full focus:outline-none'>
          Create Game
        </button>
      </form>
    </>
  );
};

export default NewGame;

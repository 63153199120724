import React, { useState } from "react";

const Accordion = ({ data, keyId }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className='p-2 border-b-2 bg-gray-300 border-purple-600 bg-black-400 '>
      <div
        className='accTitle py-2 flex cursor-pointer'
        onClick={() => {
          setOpen(!open);
        }}>
        <span className='font-bold px-3'>{data.lang}</span>
        <span className='ml-auto text-xl px-3'>{!open ? "+" : "-"}</span>
      </div>
      <div
        className={`accContent my-4 ${!open ? "hidden" : ""}`}
        dangerouslySetInnerHTML={{ __html: `${data.text}` }}
      />
    </div>
  );
};

export default Accordion;

import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "./Svg";

const Login = (props) => {
  const email = useRef(null);
  const password = useRef(null);
  const pin = useRef(null);
  let navigation = useNavigate();
  const [error, setError] = useState(null);
  const [showmodal, setshowmodal] = useState(false);
  const [pinError, setPinError] = useState(null);

  const login = (e) => {
    setError(null);
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URI}/api/auth`, {
      method: "POST",
      body: JSON.stringify({
        email: email.current.value,
        password: password.current.value,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            if (json.verified) {
              localStorage.setItem("token", json.token);
              navigation("/");
            } else {
              setshowmodal(true);
            }
          } else {
            console.log(json.errors[0].msg);
            setError(json.errors[0].msg);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verify = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/auth/verify`, {
      method: "POST",
      body: JSON.stringify({
        email: email.current.value,
        pin: pin.current.value,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            localStorage.setItem("token", json.token);
            navigation("/");
          } else {
            console.log(json.errors[0].msg);
            setPinError(json.errors[0].msg);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closePinModal = () => {
    setshowmodal(false);
  };
  return (
    <>
      <div className='min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-md w-full'>
          <div>
            <Logo />
            <h2 className='mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900'>
              Sign in to your account
            </h2>
            <p className='mt-2 text-center text-sm leading-5 text-gray-600'>
              Or
              <a
                href='/register'
                style={{ color: "#583687" }}
                className='font-medium focus:outline-none focus:underline transition ease-in-out duration-150'>
                {" "}
                Register
              </a>
            </p>
          </div>
          <form className='mt-8' action='/' onSubmit={(e) => login(e)}>
            <input type='hidden' name='remember' value='true' />
            <div className='rounded-md shadow-sm'>
              <div>
                <input
                  aria-label='Email address'
                  name='email'
                  type='email'
                  required
                  className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-purple focus:border-purple-600 focus:z-10 sm:text-sm sm:leading-5'
                  placeholder='Email address'
                  ref={email}
                />
              </div>
              <div className=''>
                <input
                  aria-label='Password'
                  name='password'
                  type='password'
                  required
                  className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-purple focus:border-purple-600 focus:z-10 sm:text-sm sm:leading-5'
                  placeholder='Password'
                  ref={password}
                />
              </div>
            </div>

            <div className='mt-6 flex items-center justify-between'>
              <div className='flex items-center'>
                <p className='text-sm hover:underline'>
                  <a href='/reset'>Did you forget your password? </a>
                </p>
              </div>
              {/* 
              <div className="text-sm leading-5">
                {error ? error : null}
              </div> */}
              {error ? (
                <div className='absolute top-0 mt-4 inline-flex max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden'>
                  <div className='flex justify-center items-center w-12 bg-red-500'>
                    <svg
                      className='h-6 w-6 fill-current text-white'
                      viewBox='0 0 40 40'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path d='M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z'></path>
                    </svg>
                  </div>
                  <div className='-mx-3 py-2 px-4'>
                    <div className='mx-3'>
                      <span className='text-red-500 font-semibold'>Error</span>
                      <p className='text-gray-600 text-sm'>{error}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className='mt-6'>
              <button
                type='submit'
                style={{ background: "#583687" }}
                className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white  focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>
                <span className='absolute left-0 inset-y-0 flex items-center pl-3'>
                  <svg
                    className='h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150'
                    fill='#fff'
                    viewBox='0 0 20 20'>
                    <path
                      fillRule='evenodd'
                      d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z'
                      clipRule='evenodd'
                    />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className='fixed z-10 inset-0 overflow-y-auto'
        style={{ display: showmodal ? "block" : "none" }}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <div className='fixed inset-0 transition-opacity'>
            <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
          </div>

          <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>

          <div
            className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'>
            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div className='sm:flex sm:items-start'>
                <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                  <svg
                    className='h-6 w-6 text-red-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#583687'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                    />
                  </svg>
                </div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <h3
                    className='text-lg leading-6 font-medium text-gray-900'
                    id='modal-headline'>
                    Fill the 4 digit number we sent to your email
                  </h3>
                  <div className='mt-4'>
                    <p className='text-sm leading-5 text-gray-500'>
                      <input
                        type='text'
                        required
                        className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-purple focus:border-purple-600 focus:z-10 sm:text-sm sm:leading-5'
                        ref={pin}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-gray-50 px-4 py-3 sm:px-6'>
              <span className='flex '></span>
            </div>
            <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
              <span className='flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto'>
                <button
                  type='button'
                  className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-purple-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                  onClick={(e) => {
                    verify(e);
                  }}>
                  Verify
                </button>
              </span>
              <span className='mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto'>
                <button
                  type='button'
                  className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                  onClick={closePinModal}>
                  Cancel
                </button>
              </span>
              <span className='sm:mr-3'>{pinError ? pinError : null}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import Games from "../Dashboard/Games";
import Profile from "../Dashboard/Profile";
import NewGame from "../Dashboard/New_game";
import Game from "../Dashboard/Game";

const Dashboard = () => {
  const history = useNavigate();
  const [showProfile, setshowProfile] = useState(false);
  const [profile, setProfile] = useState({});
  const profileRef = useRef(null);
  const profileMenu = useRef(null);
  const logoutAction = useRef(null);
  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URI}/api/auth`,
          {
            method: "GET",
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        );
        const json = await response.json();
        response.ok ? setProfile(json) : console.log(response.statusText);
      } catch (error) {
        console.log(error);
      }
    };
    getProfile();
  }, []);
  useEffect(() => {
    // document.addEventListener("click", handleClickOutside);
    // return () => {
    //   document.removeEventListener("click", handleClickOutside);
    // };
  }, []);
  const logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    history("/login");
  };
  const showProfileMenu = () => {
    showProfile ? setshowProfile(false) : setshowProfile(true);
  };
  // const handleClickOutside = (e) => {
  //   if (
  //     profileRef &&
  //     !profileRef.current.contains(e.target) &&
  //     profileMenu &&
  //     !profileMenu.current.contains(e.target)
  //   ) {
  //     setshowProfile(false);
  //   }
  // };

  return (
    <div className='flex h-screen bg-gray-200'>
      <div className='translate-x-30 ease-out fixed z-30 inset-y-0 left-0 w-12 lg:w-64 transition overflow-x-hidden duration-300 transform bg-gray-900 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0'>
        <div className='flex  lg:px-3 mt-8'>
          <div className='flex items-center'>
            {/* <svg
              className='h-12 w-12 flex-shrink-0'
              viewBox='0 0 512 512'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M364.61 390.213C304.625 450.196 207.37 450.196 147.386 390.213C117.394 360.22 102.398 320.911 102.398 281.6C102.398 242.291 117.394 202.981 147.386 172.989C147.386 230.4 153.6 281.6 230.4 307.2C230.4 256 256 102.4 294.4 76.7999C320 128 334.618 142.997 364.608 172.989C394.601 202.981 409.597 242.291 409.597 281.6C409.597 320.911 394.601 360.22 364.61 390.213Z'
                fill='#583687'
                stroke='#583687'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'></path>
              <path
                d='M201.694 387.105C231.686 417.098 280.312 417.098 310.305 387.105C325.301 372.109 332.8 352.456 332.8 332.8C332.8 313.144 325.301 293.491 310.305 278.495C295.309 263.498 288 256 275.2 230.4C256 243.2 243.201 320 243.201 345.6C201.694 345.6 179.2 332.8 179.2 332.8C179.2 352.456 186.698 372.109 201.694 387.105Z'
                fill='white'></path>
            </svg> */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 16.98 21.84'
              className='h-12 w-12 flex-shrink-0'>
              <defs />
              <g id='Layer_2' data-name='Layer 2'>
                <g id='svg'>
                  <path
                    fill='#F48705'
                    d='M8.85 0h-.07.07l.41.09c2.3.48 3.69 1.28 4 2.28.36 1.19-1.33 2.4-4 2.89-.8.15-.73.17.19.1 2.28-.2 4-.78 4.85-1.67 1.52-1.54-.86-3.34-5-3.69h-.46M16.77 5.09a1 1 0 0 0-.05.24c-.39 2.76-1.91 4.12-3.84 3.45A6.22 6.22 0 0 1 11 7.56a3.51 3.51 0 0 0-.6-.51 11 11 0 0 0 .92 1.46 6.07 6.07 0 0 0 .68.79 2.82 2.82 0 0 0 2.74 1c1.34-.31 2-1.77 2.08-4.63v-.61h-.07M6 5.83c-1.19.1-2 .6-2.21 1.34-.39 1.46.14 2.72 1.89 4.54.34.34.39.36.22.09-1.05-1.8-1.27-3.12-.69-4.26.39-.78 1.7-1.24 3.62-1.28 1 0 1 0 .14-.19A9.79 9.79 0 0 0 6 5.83M7.21 12.58a.84.84 0 0 0 0 .29C7 15.15 5 17.26.82 19.64c-.33.19-.65.36-.72.36l-.1.05v.82a22 22 0 0 0 2.09-.82c3.62-1.6 6.24-4.13 6.86-6.62a1.63 1.63 0 0 0 .05-.8 16.75 16.75 0 0 0-1.8-.05M9.56 12.66a5 5 0 0 0 0 .66c0 .78-.05.71.54.71 2.3 0 3.88.48 4.56 1.36 1.05 1.39.32 3-2.3 5-1.09.82-1 .73-1.62 1.19-.48.34-.46.36.05.14a22.15 22.15 0 0 0 2.84-1.53c2.5-1.63 3.62-3.28 3.3-4.85-.27-1.34-1.87-2.19-4.71-2.5a19.34 19.34 0 0 0-2.65-.15'
                  />
                </g>
              </g>
            </svg>
            <span className='text-white text-2xl mx-2 font-semibold whitespace-nowrap'>
              Dashboard
            </span>
          </div>
        </div>
        <nav className='mt-10 overflow-x-hidden'>
          <Link
            to='/'
            className='flex flex-nowrap whitespace-nowrap lg:items-center mt-4 py-2 px-3 lg:px-6 bg-gray-700 bg-opacity-25 text-gray-100'>
            <svg
              className='h-6 w-6 flex-shrink-0'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z'></path>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z'></path>
            </svg>

            <span className='mx-3'>Dashboard</span>
          </Link>
          <Link
            to='/new-game'
            className='flex flex-nowrap whitespace-nowrap items-center mt-4 py-2 px-3 lg:px-6  bg-gray-700 bg-opacity-25 text-gray-100'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 flex-shrink-0'
              viewBox='0 0 100 100'>
              <path
                fill='#fff'
                d='M73.1 47.3c0-1.4-1.1-2.5-2.5-2.5H57c-1 0-1.9-.8-1.9-1.9V29.3c0-1.4-1.1-2.5-2.5-2.5h-5.5c-1.4 0-2.5 1.1-2.5 2.5V43c0 1-.8 1.9-1.9 1.9H29.3c-1.4 0-2.5 1.1-2.5 2.5v5.5c0 1.4 1.1 2.5 2.5 2.5H43c1 0 1.9.8 1.9 1.9v13.6c0 1.4 1.1 2.5 2.5 2.5h5.5c1.4 0 2.5-1.1 2.5-2.5V57c0-1 .8-1.9 1.9-1.9h13.6c1.4 0 2.5-1.1 2.5-2.5l-.3-5.3z'
              />
              <path
                fill='#fff'
                d='M50 2.5C23.8 2.5 2.5 23.8 2.5 50S23.8 97.5 50 97.5 97.5 76.2 97.5 50 76.2 2.5 50 2.5zm0 84.6c-20.5 0-37.1-16.6-37.1-37.1S29.5 12.9 50 12.9 87.1 29.5 87.1 50 70.5 87.1 50 87.1z'
              />
            </svg>
            <span className='mx-3'>New Game</span>
          </Link>
        </nav>
      </div>
      <div className='flex-1 flex flex-col overflow-hidden'>
        <header className='flex justify-between items-center py-4 px-6 bg-white border-b-4 border-purple-600'>
          <div className='flex items-center'></div>
          <div className='flex items-center'>
            <div className='relative'>
              <button
                className='relative block h-8 w-8 rounded-full overflow-hidden shadow focus:outline-none'
                onClick={showProfileMenu}
                ref={profileRef}>
                <img
                  className='h-full w-full object-cover'
                  src={profile.avatar}
                  alt='Your avatar'
                />
              </button>

              <div
                className='fixed inset-0 h-full w-full z-10'
                style={{ display: "none" }}></div>

              <div
                className='absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10'
                style={showProfile ? { display: "block" } : { display: "none" }}
                ref={profileMenu}>
                {/* <a href="/profile"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">Profile</a> */}
                <Link
                  to='/profile'
                  className='block px-4 py-2 text-sm text-gray-700 hover:bg-purple-700 hover:text-white'>
                  Profile
                </Link>
                <Link
                  to='/'
                  className='block px-4 py-2 text-sm text-gray-700 hover:bg-purple-700 hover:text-white'
                  onClick={(e) => logOut(e)}
                  ref={logoutAction}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </header>
        <main className='ml-12 lg:ml-0  overflow-y-auto bg-gray-200'>
          <Routes>
            <Route path='/' element={<Games />} />
            <Route path='/profile' element={<Profile profile={profile} />} />
            <Route path='/new-game' element={<NewGame />} />
            <Route path='/game/:gameId' element={<Game />} />
            <Route path='*' element={<NoMatch />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </div>
  );
}

export default Dashboard;

import React from "react";
import { Navigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const ProtectedRoute = (props) => {
  // const Component = props.component;

  const token = localStorage.getItem("token");

  let isAuthenticated = false;
  if (token) {
    let tokenExpire = jwtDecode(token).exp;
    let dateNow = Date.now() / 1000;
    tokenExpire < dateNow
      ? (isAuthenticated = false)
      : (isAuthenticated = true);
  }

  return isAuthenticated ? props.children : <Navigate to='/login' />;
};

export default ProtectedRoute;

import React from "react";
import ReactDOM from "react-dom";
import "./tailwind.generated.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Register from "./components/Register";
import NewPassword from "./components/NewPassword.js";
import Reset from "./components/Reset.js";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/reset/' element={<Reset />} />
      <Route path='/password/reset/:userId/:token' element={<NewPassword />} />
      <Route
        path='/*'
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      {/* <Route element={<Dashboard />} /> */}
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

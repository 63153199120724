import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Accordion from "../components/Accordion";

const Game = () => {
  let { gameId } = useParams();
  const [game, setGame] = useState(null);
  const [accData, setAccData] = useState(null);

  useEffect(() => {
    getGame(gameId);
    const timer = setInterval(() => {
      getGame(gameId);
    }, 7000);
    return () => clearInterval(timer);
  }, [gameId]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    fetch("/instructions.json").then((response) => {
      response.json().then((json) => {
        if (response.ok) {
          setAccData(json);
        } else {
          console.log(json.errors);
        }
      });
    });
  }

  const getGame = (gameId) => {
    fetch(`${process.env.REACT_APP_API_URI}/api/games/${gameId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            setGame(json.game);
          } else {
            console.log(json.errors);
          }
        });
      })
      .catch((error) => {
        return error;
      });
  };
  // useEffect(() => {
  //     const socket = socketIOClient(`${process.env.REACT_APP_API_URI}/`,
  //         {
  //             // transports: ['websocket'],
  //             //     agent: false,
  //             //     upgrade: false,
  //             //     rejectUnauthorized: false
  //         }
  //     );
  //     socket.on('change', (mess) => {
  //         console.log(mess)
  //     })
  //     socket.on('newplayer', (data) => {
  //         console.log(data)
  //         setGame(data);
  //     });
  // }, [])

  return (
    <div className='mb-24'>
      <div className='container  px-6 py-8 mb-6 mb-24'>
        <h3 className='text-gray-700 text-3xl font-medium'>
          Game Name: {game ? game.name : null}
        </h3>
        <h3 className='text-gray-700 text-3xl font-medium'>
          Game ID: {game ? game.gameId : null}
        </h3>
        <h3 className='text-gray-700 text-3xl font-medium'>
          Game Date: {game ? game.date.substring(0, 10) : null}
        </h3>
      </div>

      {game
        ? game.players.map((player, i) => (
            <div key={i}>
              <h3 className='mx-4 mt-10 mb-4 font-bold text-xl'>
                #{i + 1} {player.user_name}
              </h3>
              <table className='min-w-full '>
                <thead>
                  <tr>
                    <th colSpan='13' className=' bg-gray-500'>
                      Assesment
                    </th>
                  </tr>
                  <tr>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      COM
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      PPP
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      PCP
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      PR
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      PS
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      PUB
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      Total part 1
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      MDM
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      MD
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      Total part 2
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      IMP
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      VUL
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      Total part 3
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      Total Part 4
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  <tr className='group transition duration-300 hover:bg-yellow-200 cursor-pointer text-gray-900 '>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300   border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_1 ? player.part_1.COM_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300   border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_1 ? player.part_1.PPP_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300  border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_1 ? player.part_1.PCP_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300  border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_1 ? player.part_1.PR_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300  border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_1 ? player.part_1.PS_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300  border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium '>
                          {player.part_1 ? player.part_1.PUB_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap bg-gray-300 group-hover:bg-yellow-300 border-b  border-gray-200 transition duration-300 group-hover:bg-yellow-300'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_1 ? player.part_1.TOTAL_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_2 ? player.part_2.MDM_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_2 ? player.part_2.MD_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap transition duration-300 border-b border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_2 ? player.part_2.TOTAL_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300  border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_3 ? player.part_3.IMP_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300  border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_3 ? player.part_3.VUL_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap bg-gray-300 group-hover:bg-yellow-300 bg-gray-300  transition duration-300  group-hover:bg-yellow-300 border-b border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_3 ? player.part_3.TOTAL_score : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b  transition duration-300  border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.part_4
                            ? `${player.part_4.TOTAL_correct} / ${player.part_4.TOTAL_nr}`
                            : "--"}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className='min-w-full mt-8s'>
                <thead>
                  <tr>
                    <th colSpan='2' className='200 bg-gray-500'>
                      Evaluation
                    </th>
                  </tr>
                  <tr>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      Healthy nutrition
                    </th>
                    <th className='px-2 py-3 border-b border-gray-200 bg-gray-400 text-left text-xs leading-4 font-medium text-gray-0 uppercase tracking-wider'>
                      Prohibited substances
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  <tr className='group transition duration-300 hover:bg-yellow-200 cursor-pointer text-gray-900 '>
                    <td className='px-2 py-4 whitespace-no-wrap border-b  border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.evaluation
                            ? player.evaluation.NUTRITION_score
                            : "--"}
                        </div>
                      </div>
                    </td>
                    <td className='px-2 py-4 whitespace-no-wrap border-b bg-gray-300 group-hover:bg-yellow-300 border-gray-200'>
                      <div className='flex items-center'>
                        <div className='text-sm leading-5 font-medium'>
                          {player.evaluation
                            ? player.evaluation.SUBSTANCES_score
                            : "--"}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))
        : null}
      <div className='accordion container  px-6 py-2'>
        <div className='py-2'>
          {accData
            ? accData.data.map((data, i) => (
                <Accordion data={data} key={"accordion_" + i} />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default Game;
